.document-result-content  {
    color: gray;
    border: 1px solid dimgray;
    border-radius: 5px;
    padding: 1rem;
    font-size: 150%;
}

.document-result-title  {
    margin-left: 1rem;
}

.word-result  {
    cursor: default;
}

.splitter-result  {
    cursor: default;
}

