body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root  {
  height: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.document-result-content  {
    color: gray;
    border: 1px solid dimgray;
    border-radius: 5px;
    padding: 1rem;
    font-size: 150%;
}

.document-result-title  {
    margin-left: 1rem;
}

.word-result  {
    cursor: default;
}

.splitter-result  {
    cursor: default;
}


.sidebar {
    background: #7386D5;
    color: #fff;
    position: fixed;
    width: 260px;
    padding-left: 5px;
    padding-right: 5px;
}

.sidebar-content {
    position: absolute;
    left: 0;
    top: 10%;
    width: 100%;
    text-align: center;
}

.sidebar-footer {
    position: absolute;
    left: 0;
    bottom: 5%;
    width: 100%;
    text-align: center;
}

.document-list  {
    width: calc(100% - 280px);
    margin-left: 270px;
    margin-right: 10px;
}

.github-link {
    color: white;
    text-decoration: none;
}

html,body {
    height:100%;
}





